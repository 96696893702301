import { message, Modal, Button, notification, Upload } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import classes from './AdminMobileApk.module.css'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const AdminMobileApk = () => {
  const [fileList, setFileList] = useState([])
  const [refetchTrigger, setRefetchTrigger] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const axiosPrivate = useAxiosPrivate()

  useEffect(() => {
    const getApkUrl = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/get-apk-url`)
        setFileList([
          {
            uid: '-1',
            name: response?.data?.apk?.filename || 'current_apk.apk',
            status: 'done',
            url: response?.data?.apk?.url,
          },
        ])
      } catch (err) {
        console.error('Error fetching apk url:', err)
      }
    }

    getApkUrl()
  }, [refetchTrigger])

  const handleUpdateApkUrl = async () => {
    if (fileList.length === 0) {
      notification.error({
        message: 'Molimo vas da odaberete APK fajl za upload',
      })
      return
    }

    const file = fileList[0]?.originFileObj
    const apkVersion = fileList[0]?.name // Extract filename as apkVersion

    const formData = new FormData()
    formData.append('apk', file)
    formData.append('apkVersion', apkVersion) // Send filename as apkVersion

    try {
      await axiosPrivate.post(`${SERVER_URL}/add-apk-url`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      notification.success({
        message: 'Uspešno ste ažurirali APK fajl',
      })
      setIsEditModalVisible(false)
    } catch (error) {
      notification.error({
        message:
          error.response?.data?.message ||
          'Došlo je do greške prilikom ažuriranja APK fajla',
      })
    }
    setRefetchTrigger((curr) => !curr)
  }

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList.slice(-1)) // Keep only the latest file
  }

  return (
    <div className={classes.apkUrlPageWrapper}>
      <div className={classes.topWrapper}>
        <h2>APK Fajl za aplikaciju</h2>
        <button onClick={() => setIsEditModalVisible(true)}>
          Ažuriraj APK
        </button>
      </div>

      {/* Show current APK link */}
      {fileList.length > 0 && fileList[0]?.url && (
        <a href={fileList[0]?.url} download>
          Preuzmite trenutni APK
        </a>
      )}

      {/* Edit apkUrl Modal */}
      <Modal
        title='Ažuriraj APK fajl'
        open={isEditModalVisible}
        onOk={handleUpdateApkUrl}
        onCancel={() => setIsEditModalVisible(false)}
        className={classes.apkUrlModal}
        okText='Ažuriraj'
        cancelText='Otkaži'
      >
        <div className={classes.upload}>
          <Upload
            accept='.apk'
            beforeUpload={() => false} // Prevent auto upload
            fileList={fileList}
            onChange={handleFileChange}
            maxCount={1} // Only allow one file
          >
            <Button icon={<UploadOutlined />}>Odaberite APK fajl</Button>
          </Upload>
        </div>
      </Modal>
    </div>
  )
}

export default AdminMobileApk
