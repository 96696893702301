import { useState } from 'react'
import axios from '../config/axios'
import { useQuery } from '@tanstack/react-query'
import SinglePost from '../components/SinglePost'
import Spinner from '../components/Spinner'
import classes from './home.module.css'
import classesKP from './korisnickiPaketiLista.module.css'
import { useNavigate } from 'react-router-dom'

const KorisnickiPaketiLista = () => {
  const [posts, setPosts] = useState([])
  const navigate = useNavigate()
  const [isMsg, setIsMsg] = useState(false)

  const getAllKorisnickiPaketi = async () => {
    const { data } = await axios.get('/users-packages')
    data.msg ? setIsMsg(true) : setPosts(data.result)
    !data.msg ? setIsMsg(false) : setPosts([])
    return data
  }
  const allKorisnickiPaketi = useQuery({
    queryKey: ['korisnickiPaketi'],
    queryFn: getAllKorisnickiPaketi,
    staleTime: 0,
    refetchInterval: 1000 * 60 * 60 * 12,
  })

  return (
    <div className={classes.container}>
      {/* <button
        className={classesKP.buyBtn}
        onClick={() => navigate('/korisnicki_paketi/kupi-paket')}
      >
        <div className={classesKP.starWrapper}>
          <img src='/star.svg' />
        </div>
        <div>
          <span>KAKO ? BRZO I LAKO !</span>
          <span className={classesKP.midTxt}>KLIKNI I KUPI</span>
          <span>BETKO PAKETE</span>
        </div>
        <div className={classesKP.starWrapper}>
          <img src='/star.svg' />
        </div>
      </button> */}
      {allKorisnickiPaketi.isLoading || posts.length === 0 ? (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      ) : (
        <>
          {!isMsg ? (
            posts.map((post) => {
              return (
                <SinglePost
                  key={post._id}
                  postId={post._id}
                  post={post}
                  type='korisnickiPaketi'
                />
              )
            })
          ) : (
            <div className={classes.msg}>
              <p>{allKorisnickiPaketi.data.msg}</p>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default KorisnickiPaketiLista
